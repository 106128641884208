<template>
  <div class="terminal">
    <div class="left_imgs">
      <img class="left_img" src="../assets/img/left_quan.png" alt="" />
    </div>
    <div class="rnt_cont">
      <div class="geRen">
        <div class="geRen_bian"></div>
        <div class="geRen_top gongBg">
          <div></div>
        </div>
        <div class="geRen_title"> 权益 </div>
        <div class="title_jian"></div>
        <div class="geRen_list">
          <div class="geRen_ccont">
            <div class="jian"></div>
            <div class="geRen_list_li">
              <div class="geRen_left gongBg">曲库更新</div>
              <div class="geRen_rnt">每天</div>
            </div>
            <div class="jian"></div>
            <div class="geRen_list_li">
              <div class="geRen_left gongBg">下载速度</div>
              <div class="geRen_rnt">极速</div>
            </div>
            <div class="jian"></div>
            <div class="geRen_list_li">
              <div class="geRen_left gongBg">录音保存</div>
              <div class="geRen_rnt">1000首</div>
            </div>
            <div class="jian"></div>
            <div class="geRen_list_li">
              <div class="geRen_left gongBg">语音点歌</div>
              <div class="geRen_rnt">无唤醒</div>
            </div>
            <div class="jian"></div>
            <div class="geRen_list_li">
              <div class="geRen_left gongBg">手机点歌</div>
              <div class="geRen_rnt">有</div>
            </div>
          </div>
        </div>
        <div class="geRen_bian"></div>
      </div>
      <div class="quanyi">
        <div class="geRen_bian"></div>
        <div class="quanyi_title">
          <span class="span">升级个人权益</span>
        </div>
        <div class="quanyi_list">
          <div class="quanyi_bian"></div>
          <div class="gongBg" v-for="(item,index) in listData" :key="index">1</div>
          <!-- <div class="quanyi_bian"></div> -->
          <!-- <div class="gongBg">2</div>
          <div class="quanyi_bian"></div>
          <div class="gongBg">3</div> -->
        </div>
        <div class="geRen_bian"></div>
      </div>
      <div class="erweima">
        <div class="erweima_title"></div>
        <div class="erweima_cont gongBg"></div>
        <div class="geRen_bian "></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listData:[
        {
          yue:'6个月',
          jia:'¥128'
        },
        {
          yue:'6个月',
          jia:'¥128'
        },
        {
          yue:'6个月',
          jia:'¥128'
        }
      ]
    };
  },
};
</script>

<style scoped>
.terminal {
  height: 100vh;
  width: 100%;
  background: url("../assets/img/page_bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
}
.gongBg {
  background: rgba(225, 225, 225, 0.08);
}
.left_imgs {
  height: 100%;
}
.left_img {
  height: 100%;
}
.rnt_cont {
  flex: 1;
  height: 100%;
  margin-left: 54px;
  display: flex;
  justify-content: space-between;
}

/* 个人权益 */
.geRen {
  width: 36.3%;
  margin-left: 3.4%;
  /* padding: 6.795% 0px; */
}
.geRen_bian {
  height: 6.795%;
}
.geRen_top {
  height: 20.75%;
  border-radius: 10px;
}
.geRen_title {
  height: 11.73%;
  display: flex;
  align-items: flex-end;
  font-size: 24px;
  font-weight: 500;
  color: #ffd890;
}
.title_jian{
  height: 1.1%;
}
.geRen_list {
  height: 52.83%;
}
.geRen_ccont {
  height: 100%;
}
.jian{
  height: 3.57%;
}
.geRen_list_li {
  height: 16.43%;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.geRen_left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 34.375%;
  margin-right: 2.23%;
  border-radius: 6px;
  color: rgba(225, 225, 225, 0.5);
}
.geRen_rnt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 63.395%;
  background: rgba(242, 208, 142, 0.2);
  color: #ffd78e;
  border-radius: 6px;
}
/* 升级类型 */
.quanyi {
  width: 30.1%;
  height: 100%;
  margin: 0px 2.75%;
}
.quanyi_title {
  height: 5.47%;
}
.span{
  font-size: 26px;
  background: linear-gradient(to right, #FFC52C, #FF8D00);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}
.quanyi_list {
  height: 80.94%;
}
.quanyi_list>div{
  height: 25.6433%;
  border-radius: 10px;
}
.quanyi_list >.quanyi_bian{
  height: 7.69% ;
}
/*二维码 */
.erweima {
  width: 21.2%;
  height: 100%;
  margin-right: 2.43%;
}
.erweima_title {
  height: 18.495%;
}
.erweima_cont {
  height: 74.71%;
  border-radius: 10px;
}
</style>